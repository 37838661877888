import React from 'react';
import styled from 'styled-components/macro';

import { H3, SectionContents } from '../../components/elements';
import { COLORS, MEDIA_QUERIES } from '../../constants/constants';
import ImageSrcConstants from '../../constants/ImageSrcConstants';
import Button from '../../styles/lib';

function Volunteer() {
  return (
     <VolunteerSection>
        <SectionContents>
                <VolunteerWrapper>
                    <H3>BECOME A VOLUNTEER</H3>
                    <h5>We can change everything together.</h5><br />
                    <p>Volunteering is not just an experience but a learning opportunity.
                    Teaching and interacting with kids helps one grow as a person by gaining knowledge and confidence.
                    ITB family is an opportunity to bring your ideas and implement them.
                    Together we can make a difference. Let’s drive the Change.<br /><br />
                    </p>
                    <a href={ImageSrcConstants.VOLUNTEER_REG_FORM} rel="noopener noreferrer" target="_blank">
                        <Button>VOLUNTEER</Button>
                    </a>
                </VolunteerWrapper>
                <ImageWrapper>
                    <Img src={ImageSrcConstants.VOLUNTEER} alt="Volunteer with kids" />
                </ImageWrapper>
            </SectionContents>
      </VolunteerSection>
    )
}

const VolunteerSection = styled.section`
    margin-top: 24px;
    padding-left: 10vw;
    background-color: ${COLORS.dark};
    color: ${COLORS.white};
    justify-content: space-between;
    @media ${MEDIA_QUERIES.bigTabletAndSmaller} {
        padding-left: 5vw;
    }
`;

const VolunteerWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 0 30px;
    padding-right: 16px;
    min-width: 50%;
`;

const ImageWrapper = styled.div`
    min-width: 50%;
    max-width: 50%;
    @media ${MEDIA_QUERIES.tabletAndSmaller} {
        display: none;
    }
`;

const Img = styled.img`
    display: block;
    object-fit: cover;
    width: 100%;
    height: 42vh;
`;


export default Volunteer;
