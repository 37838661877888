const events = {
  EVENTS: [
    {
      place: "Grammar books",
      dateType: "year",
      year: "2021",
      img: [
      ],
      videos: [
      ],
      content: "Donated 22 English grammar books containing activities to make it easier for kids to understand the concepts better. During the pandemic, going to schools physically and teaching kids was not possible. Interactive grammar books were used to help kids learn.",
    },
    {
      place: "Social Media Campaign",
      dateType: "year",
      year: "2019",
      img: [
      ],
      videos: [
      ],
      content: "This donation drive was motivated by the concept \"Hani hani koodi Halla\". People were encouraged to donate small amounts which would add up to a significant amount. 25 people contributed an amount of ₹36401. 14 people donated stationeries including 10 boxes of stapled books.",
    },
    {
      place: "Kolar",
      dateType: "year",
      year: "2019",
      img: [
        {
          src: "https://drive.google.com/uc?export=view&id=11eMQ_OUWRr7Nw8cM-YTGRRMysvDDPrq3",
          altText: "Cultural day at school",
        },
        {
          src: "https://drive.google.com/uc?export=view&id=12v1WHhU-lU-GBKZSJvDEf7mGzX-mCgGR",
          altText: "Prizes for kids",
        },
      ],
      videos: [
        "https://www.youtube.com/watch?v=_0T1u752AI0",
      ],
      content: "This event was conducted in a remote village in Kolar as a part of the Independence Day donation drive. Each kid was donated around 6 notebooks. A cultural event was organised to motivate the kids to showcase their talent.",
    },
    {
      place: "Channapatna",
      dateType: "year",
      year: "2018",
      img: [
        {
          src: "https://drive.google.com/uc?export=view&id=12JFGZ1gt40AG3YHzN-ZM6Ad5l8sdtsrT",
          altText: "Kids at school",
        }
      ],
      videos: [
        "https://www.youtube.com/watch?v=BxPslSEm5yU",
      ],
      content: "Two of the volunteers visited the school and interacted with the kids to understand their difficulties. Most kids belong below the poverty line. Often these kids don't receive encouragement from parents to study and sometimes the teachers have to go to their house to bring them to school. As a part of this donation drive, exam pads, graph books, drawing books, colour pencils, pen and pencils were donated.",
    },
    {
      place: "Sneha Jyothi",
      dateType: "year",
      year: "2018",
      img: [
        {
          src: "https://drive.google.com/uc?export=view&id=11fegFke7KuMDnzfM-XlCWsNCHjRuIjjU",
          altText: "Happy children",
        },
        {
          src: "https://drive.google.com/uc?export=view&id=114hGz1Y2kOda1os8MmzXacsaxVMXEQVe",
          altText: "Kids and volunteers",
        }
      ],
      videos: [
      ],
      content: "A survey was conducted to understand the needs of the kids. Most of the kids were from either orphanages or poor backgrounds. In this donation drive, old clothes, stationeries and food were donated. During the next visit, one of the volunteers sponsored food for the kids. In the visit after that, a friend of one of the volunteers sponsored food for the kids. This was discontinued since most orphanages were getting enough funds.",
    },
    {
      place: "Old age home",
      dateType: "date",
      date: "13",
      month: "May",
      year: "2018",
      img: [
      ],
      videos: [
      ],
      content: "The volunteers visited to participate in the birthday event of one of the members. They distributed fruits and sweets to celebrate. Old clothes were donated at the old age home.",
    },
    {
      place: "Social media campaign",
      dateType: "date",
      date: "5",
      month: "Feb",
      year: "2018",
      img: [
      ],
      videos: [
      ],
      content: "This was one of the first donation drives. A small effort towards making a difference. As a part of this drive, 12 people donated old clothes and stationeries were collected. Another 12 people donated a total amount of ₹7500.",
    },
    {
      place: "Shalom Grace Children's home",
      dateType: "year",
      year: "2016",
      img: [
        {
          src: "https://drive.google.com/uc?export=view&id=1TMjCU_TmRk75i7y3oYtpihbDO98Y_s_a",
          altText: "Orphanage visit",
        }
      ],
      videos: [
      ],
      content: "On the occasion of one of the cofounder’s birthday, the team visited this orphanage near Hennur, Bangalore. Lunch was sponsored for the kids. The kids were delighted. They showcased their talent to celebrate the event.",
    },
  ],
}

export default events;