import React from "react";
import styled from "styled-components/macro";

function Year({ year }) {

  return (
    <YearWrapper>
      <H3> {year} </H3>
      <Line />
    </YearWrapper>

  );
};

const YearWrapper = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
`;

const Line = styled.div`
    width: 50px;
    height: 3px;
    margin-bottom: 10px;
    background: #F02A3E;
`;

const H3 = styled.h3`
    font-size: 1.5rem;
    margin: 5px 0;
`;

export default Year;
