import React, { Component } from 'react';
import { H3, Section, PageHeading } from '../../components/elements';
import trusteesInfo from '../../resources/about/team/trustees'
import advisoryBoardInfo from '../../resources/about/team/advisoryBoard'
import projectLeadsInfo from '../../resources/about/team/projectLeads'
import '../../css/about/team.css';
import styled from "styled-components/macro";
import { MEDIA_QUERIES } from '../../constants/constants';

class Team extends Component {
    render() {
        return (
            <Section>
                <Web>
                    <PageHeading>MEET THE TEAM</PageHeading>
                    <Category>
                        <H3 id="trustees">TRUSTEES</H3>
                        <Row>
                            {trusteesInfo.DATA.slice(0, 4).map((info, i) => (
                                <div className="card">
                                    <img className="member-img" src={info.img} alt="" />
                                    <figcaption>
                                        <Name>{info.name}</Name>
                                        <Designation>{info.designation}</Designation>
                                    </figcaption>
                                </div>
                            ))}
                        </Row>
                        <Row>
                            {trusteesInfo.DATA.slice(4, 8).map((info, i) => (
                                <div className="card">
                                    <img className="member-img" src={info.img} alt="" />
                                    <figcaption>
                                        <Name>{info.name}</Name>
                                        <Designation>{info.designation}</Designation>
                                    </figcaption>
                                </div>
                            ))}
                        </Row>
                    </Category>

                    <Category>
                        <H3 id="board">ADVISORY BOARD</H3>
                        <Row>
                            {advisoryBoardInfo.DATA.map((info, i) => (
                                <div className="card">
                                    <img className="member-img" src={info.img} alt="" />
                                    <figcaption>
                                        <Name>{info.name}</Name>
                                        <Designation>{info.designation}</Designation>
                                    </figcaption>
                                </div>
                            ))}
                        </Row>
                    </Category>

                    <Category>
                        <H3 id="leads">PROJECT LEADS</H3>
                        <Row>
                            {projectLeadsInfo.DATA.slice(0, 4).map((info, i) => (
                                <div className="card">
                                    <img className="member-img" src={info.img} alt="" />
                                    <figcaption>
                                        <Name>{info.name}</Name>
                                        <Designation>{info.designation}</Designation>
                                    </figcaption>
                                </div>
                            ))}
                        </Row>
                        <Row>
                            {projectLeadsInfo.DATA.slice(4, 8).map((info, i) => (
                                <div className="card">
                                    <img className="member-img" src={info.img} alt="" />
                                    <figcaption>
                                        <Name>{info.name}</Name>
                                        <Designation>{info.designation}</Designation>
                                    </figcaption>
                                </div>
                            ))}
                        </Row>
                        <Row>
                            {projectLeadsInfo.DATA.slice(8, 12).map((info, i) => (
                                <div className="card">
                                    <img className="member-img" src={info.img} alt="" />
                                    <figcaption>
                                        <Name>{info.name}</Name>
                                        <Designation>{info.designation}</Designation>
                                    </figcaption>
                                </div>
                            ))}
                        </Row>
                    </Category>
                </Web>

                <Mobile>
                    <PageHeading>MEET THE TEAM</PageHeading>
                    <Category>
                        <H3 id="trustees">TRUSTEES</H3>
                        <Row>
                            {trusteesInfo.DATA.map((info, i) => (
                                <div className="card-mobile">
                                    <img className="member-img-mobile" src={info.img} alt="" />
                                    <figcaption>
                                        <Name>{info.name}</Name>
                                        <Designation>{info.designation}</Designation>
                                    </figcaption>
                                </div>
                            ))}
                        </Row>
                    </Category>

                    <Category>
                        <H3 id="board">ADVISORY BOARD</H3>
                        <Row>
                            {advisoryBoardInfo.DATA.map((info, i) => (
                                <div className="card-mobile">
                                    <img className="member-img-mobile" src={info.img} alt="" />
                                    <figcaption>
                                        <Name>{info.name}</Name>
                                        <Designation>{info.designation}</Designation>
                                    </figcaption>
                                </div>
                            ))}
                        </Row>
                    </Category>

                    <Category>
                        <H3 id="leads">PROJECT LEADS</H3>
                        <Row>
                            {projectLeadsInfo.DATA.map((info, i) => (
                                <div className="card-mobile">
                                    <img className="member-img-mobile" src={info.img} alt="" />
                                    <figcaption>
                                        <Name>{info.name}</Name>
                                        <Designation>{info.designation}</Designation>
                                    </figcaption>
                                </div>
                            ))}
                        </Row>
                    </Category>
                </Mobile>

            </Section>
        )
    }
}

const Row = styled.div`
    display: flex;
    gap: 24px;
    padding-top: 24px;
    @media ${MEDIA_QUERIES.tabletAndSmaller} {
        max-width: 100%;
        flex-direction: column;
        padding: 5px;
        gap: 20px;
    }
`;

const Web = styled.div`
    @media ${MEDIA_QUERIES.tabletAndSmaller} {
        display: none;
    }
`;

const Mobile = styled.div`
    @media ${MEDIA_QUERIES.tabletAndBigger} {
        display: none;
    }
`;

const Category = styled.div`
    padding-top: 80px;
`;

const Name = styled.p`
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
`;

const Designation = styled.p`
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
`;

export default Team;
