import styled from 'styled-components/macro';
import { SOCIAL_LINKS } from "../constants/constants";
import Icon from "../styles/Icon";

function SocialIcons() {
    return (
        <SocialIconsWrapper>
            <a href={SOCIAL_LINKS.fb} target="_blank" rel="noreferrer"><Icon id="fb"/></a>
            <a href={SOCIAL_LINKS.instagram} target="_blank" rel="noreferrer"><Icon id="instagram"/></a>
            <a href={SOCIAL_LINKS.twitter} target="_blank" rel="noreferrer"><Icon id="twitter"/></a>
        </SocialIconsWrapper>
    );
}

const SocialIconsWrapper = styled.div`
    display: flex;
    margin-top: 10px;
    gap: 10px;
    a {
        color: inherit;
        &:hover: {
            color: inherit;
        }
    }
`;

export default SocialIcons;
