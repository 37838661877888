import React, { Component } from 'react';
import styled from 'styled-components/macro';

import { PageHeading } from '../../components/elements';
import general from '../../resources/faq/general';
import Accordion from '../../js/faq/Accordion'
import '../../css/faq/faq.css';
import { MEDIA_QUERIES } from '../../constants/constants';

class faq extends Component {
    render() {
        return (
            <FaqWrapper>
                <PageHeading>FREQUENTLY ASKED QUESTIONS</PageHeading>

                <div className="question">
                    {general.QUESTIONS.map((question, i) => (
                        <div>
                            <Accordion title={question.ques} content={question.ans} />
                        </div>
                    ))}
                </div>
            </FaqWrapper>
        )
    }
}

const FaqWrapper = styled.div`
    @media ${MEDIA_QUERIES.tabletAndSmaller} {
        padding: 0 16px;
    }
`;

export default faq;
