import React from "react";
import styled from "styled-components/macro";
import { COLORS } from "../constants/constants";

const SIZES = {
  small: {
    "--borderRadius": 4 + "px",
    "--fontSize": "1rem",
    "--padding": "0px 12px 0px 12px"
  },
  medium: {
    "--borderRadius": 4 + "px",
    "--fontSize": "1rem",
    "--padding": "10px 15px"
  },
  large: {
    "--borderRadius": 4 + "px",
    "--fontSize": 21 / 16 + "rem",
    "--padding": "16px 32px"
  }
};

const Button = ({ variant='primary', size='medium', styles, children }) => {
  const buttonStyles = {
    ...SIZES[size],
    ...styles,
  };

  let Component;
  if (variant === "primary") {
    Component = FillButton;
  } else if (variant === "secondary") {
    Component = OutlineButton;
  } else if (variant === "ghost") {
    Component = GhostButton;
  } else {
    throw new Error(`Unrecognized Button variant: ${variant}`);
  }

  return <Component style={buttonStyles}>{children}</Component>;
};

const ButtonBase = styled.button`
  font-family: 'Poppins', 'Open Sans',  sans-serif;
  font-size: var(--fontSize);
  font-weight: 600;
  padding: var(--padding);
  border-radius: var(--borderRadius);
  border: 2px solid transparent;
  outline-color: ${COLORS.primary};
  text-transform: uppercase;
  &:focus {
    outline-offset: 4px;
  }

  a {
      color: inherit;
      text-decoration: none;
      &:hover {
        text-decoration: none;
      }
  }
`;

const FillButton = styled(ButtonBase)`
  background-color: ${COLORS.primary};
  color: ${COLORS.white};

  &:hover {
    background-color: ${COLORS.primaryLight};
  }
`;
const OutlineButton = styled(ButtonBase)`
  background-color: ${COLORS.white};
  color: ${COLORS.primary};
  border: 2px solid currentColor;

  &:hover {
    background-color: ${COLORS.offwhite};
  }
`;
const GhostButton = styled(ButtonBase)`
  color: ${COLORS.gray};
  background-color: transparent;
  outline-color: ${COLORS.gray};

  &:hover {
    background: ${COLORS.transparentGray15};
    color: ${COLORS.black};
  }
`;

export default Button;
