export const TOPICS_MOBILE = [
  {
    img: "https://drive.google.com/uc?export=view&id=1MaPWi8xOpHOb8xOCd2DXmoMIMhpAZ4vV",
    name: "10 easy to grow medicinal plants",
    description: "List of top 10 medicinal plants one should always keep at home. These have the highest medicinal value compared to others.",
    link: "https://drive.google.com/file/d/1I1VGAEK3x7kiAkmdLlhGiHZCmx5QRivP/view?usp=sharing",
  },
  {
    img: "https://drive.google.com/uc?export=view&id=16o5n-8sZKj86meqnNE8ASJ4f9ryZdRRJ",
    name: "Top 10 air purifier plants",
    description: "List of top 10 NASA recommended air purifier plants that clean indoor air, remove toxic substances and purify the air.",
    link: "https://drive.google.com/file/d/10pU32SGLMqtFWPbNTAbsxADj4nwFXYHG/view?usp=sharing",
  },
  {
    img: "https://drive.google.com/uc?export=view&id=1bbgLFCSN2N8B_RXlGBbfyBUnxT68YxtI",
    name: "Composting at home",
    description: "Simple step by step guide to make compost at home using kitchen waste. This organic compost can be used for all types of garden plants.",
    link: "https://drive.google.com/file/d/17JKBEC1hP-V9KCGbscdxm-JOEMm_sUuT/view?usp=sharing",
  },
  {
    img: "https://drive.google.com/uc?export=view&id=16xmYSm0fHd25yspq_cJO2X-CdiIqz62T",
    name: "Grow plants from seeds",
    description: "A comprehensive guide to growing vegetables and flowers from seeds. Step by step illustration to grow plants from seeds.",
    link: "https://drive.google.com/file/d/1woxVnxW1pXh4-3liptiNt6SFpApgU6D-/view?usp=sharing",
  },
  {
    img: "https://drive.google.com/uc?export=view&id=1vEZ-yBj48_227DjP32cd-HIHH55lIbP_",
    name: "Self watering planters",
    description: "Whenever you do not have time to water plants, or are going on vacation for a while, no worries! Let the plant water by itself!",
    link: "https://drive.google.com/file/d/1qrPRAMlNcrbE31BfpPtQGtUrIWRbIObc/view?usp=sharing",
  },
  {
    img: "https://drive.google.com/uc?export=view&id=1plnxKJ9WYwLHxDtFm55xnzfassSz2pFc",
    name: "Grow plants without soil",
    description: "There are some plants that do not need soil at all. Instead, they can grow easily in water. They are easy to maintain and grow.",
    link: "https://drive.google.com/file/d/1a5IewNbVsW2EoK6I7Bf-cVSWIWFIwjVJ/view?usp=sharing",
  },
  {
    img: "https://drive.google.com/uc?export=view&id=15v6cq0Z90WodzPRL1qx_W4sn9L7LC1cq",
    name: "Succulents",
    description: "Succulents are beautiful indoor plants, low maintenance, drought resistant plants. They hardly need water.",
    link: "https://drive.google.com/file/d/15tiBA8sAxokPGXkKCy9mMtIAg-MPa681/view?usp=sharing",
  },
  {
    img: "https://drive.google.com/uc?export=view&id=1DuXaucjwGszV9xOILYN6PNAIx7rFwdb0",
    name: "Home grown vegetables",
    description: "Learn how easily you can grow vegetables at your home. See the top 10 vegetables that can be easily grown in your garden.",
    link: "https://drive.google.com/file/d/1MHMbFfE-B2YIgTXzMz3XfaLtLVBiYNe_/view?usp=sharing",
  },
  {
    img: "https://drive.google.com/uc?export=view&id=1w_t_ZwU9EvsnOCCsAaNZx6koaKgK6f_P",
    name: "Recycle or upcycle old things as planters ",
    description: "Upcycling old items in your home is a cost-effective and sustainable way to give something a new purpose and often at no extra cost. If you're feeling creative, why not have a go at upcycling common household items to grow your favourite plants.",
    link: "https://drive.google.com/file/d/1Ixu15buk5NuDCnAQvPM5VkLl9108afwQ/view?usp=sharing",
  },
]

export const TOPICS_DESKTOP = [
  [{
    img: "https://drive.google.com/uc?export=view&id=1MaPWi8xOpHOb8xOCd2DXmoMIMhpAZ4vV",
    name: "10 easy to grow medicinal plants",
    description: "List of top 10 medicinal plants one should always keep at home. These have the highest medicinal value compared to others.",
    link: "https://drive.google.com/file/d/1I1VGAEK3x7kiAkmdLlhGiHZCmx5QRivP/view?usp=sharing",
  },
  {
    img: "https://drive.google.com/uc?export=view&id=16o5n-8sZKj86meqnNE8ASJ4f9ryZdRRJ",
    name: "Top 10 air purifier plants",
    description: "List of top 10 NASA recommended air purifier plants that clean indoor air, remove toxic substances and purify the air.",
    link: "https://drive.google.com/file/d/10pU32SGLMqtFWPbNTAbsxADj4nwFXYHG/view?usp=sharing",
  }],
  [{
    img: "https://drive.google.com/uc?export=view&id=1bbgLFCSN2N8B_RXlGBbfyBUnxT68YxtI",
    name: "Composting at home",
    description: "Simple step by step guide to make compost at home using kitchen waste. This organic compost can be used for all types of garden plants.",
    link: "https://drive.google.com/file/d/17JKBEC1hP-V9KCGbscdxm-JOEMm_sUuT/view?usp=sharing",
  },
  {
    img: "https://drive.google.com/uc?export=view&id=16xmYSm0fHd25yspq_cJO2X-CdiIqz62T",
    name: "Grow plants from seeds",
    description: "A comprehensive guide to growing vegetables and flowers from seeds. Step by step illustration to grow plants from seeds.",
    link: "https://drive.google.com/file/d/1woxVnxW1pXh4-3liptiNt6SFpApgU6D-/view?usp=sharing",
  }],
  [{
    img: "https://drive.google.com/uc?export=view&id=1vEZ-yBj48_227DjP32cd-HIHH55lIbP_",
    name: "Self watering planters",
    description: "Whenever you do not have time to water plants, or are going on vacation for a while, no worries! Let the plant water by itself!",
    link: "https://drive.google.com/file/d/1qrPRAMlNcrbE31BfpPtQGtUrIWRbIObc/view?usp=sharing",
  },
  {
    img: "https://drive.google.com/uc?export=view&id=1plnxKJ9WYwLHxDtFm55xnzfassSz2pFc",
    name: "Grow plants without soil",
    description: "There are some plants that do not need soil at all. Instead, they can grow easily in water. They are easy to maintain and grow.",
    link: "https://drive.google.com/file/d/1a5IewNbVsW2EoK6I7Bf-cVSWIWFIwjVJ/view?usp=sharing",
  }],
  [{
    img: "https://drive.google.com/uc?export=view&id=15v6cq0Z90WodzPRL1qx_W4sn9L7LC1cq",
    name: "Succulents",
    description: "Succulents are beautiful indoor plants, low maintenance, drought resistant plants. They hardly need water.",
    link: "https://drive.google.com/file/d/15tiBA8sAxokPGXkKCy9mMtIAg-MPa681/view?usp=sharing",
  },
  {
    img: "https://drive.google.com/uc?export=view&id=1DuXaucjwGszV9xOILYN6PNAIx7rFwdb0",
    name: "Home grown vegetables",
    description: "Learn how easily you can grow vegetables at your home. See the top 10 vegetables that can be easily grown in your garden.",
    link: "https://drive.google.com/file/d/1MHMbFfE-B2YIgTXzMz3XfaLtLVBiYNe_/view?usp=sharing",
  }],
  [{
    img: "https://drive.google.com/uc?export=view&id=1w_t_ZwU9EvsnOCCsAaNZx6koaKgK6f_P",
    name: "Recycle or upcycle old things as planters ",
    description: "Upcycling old items in your home is a cost-effective and sustainable way to give something a new purpose and often at no extra cost. If you're feeling creative, why not have a go at upcycling common household items to grow your favourite plants.",
    link: "https://drive.google.com/file/d/1Ixu15buk5NuDCnAQvPM5VkLl9108afwQ/view?usp=sharing",
  }],
]
