import React from "react";
import styled from "styled-components/macro";
import { H4 } from '../../components/elements';

function CompleteDate({ date, month, year }) {

  return (
    <DateWrapper>
      <H3> {date} </H3>
      <Line />
      <H4>{month} {year}</H4>
    </DateWrapper>

  );
};

const DateWrapper = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
`;

const Line = styled.div`
    width: 50px;
    height: 3px;
    background: #F02A3E;
    margin-left: 15px;
`;

const H3 = styled.h3`
    font-size: 1.5rem;
    margin: 5px 0;
`;

export default CompleteDate;
