import React, { Component } from 'react';
import { Section, PageHeading } from '../../components/elements';
import '../../css/app.css';

class tnc extends Component {
    render() {
        return (
            <Section>
                <PageHeading>TERMS AND CONDITIONS</PageHeading>

                <div>
                    <p>The use of this website is provided by Inside The Box Trust subject to
                        the following terms and conditions:</p>
                    <p>1. Inside The Box Trust reserves the right to change the content of the website at any time.<br />
                        2. The contents on this website are the property of the Inside The Box Trust. They should not
                        be reproduced or used without written consent or permission of the Trust.<br />
                        3. Please request permission before using the logos or any materials published in the website.<br />
                        4. Any liabilities arising out of any act or omission by a visitor to this site, by
                        relying on or using the information provided on this site shall be solely to
                        the account of such a visitor.<br />
                        5. Donations made once cannot be cancelled, refunded or transferred.<br />
                        6. By using this website, you are accepting the terms and conditions.<br />
                        7. The Trust may change these terms and conditions from time to time. Your
                        use of the website indicates your acceptance of the terms and conditions at
                        the time of using this website.
                    </p>
                </div>
            </Section>
        )
    }
}

export default tnc;