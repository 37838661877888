import React, { Component } from 'react';
import { H3 } from '../../components/elements';
import CORE_VALUES from '../../resources/about/itb/core_values.svg';
import CORE_VALUES_DESCRIPTION from '../../resources/about/itb/core_values_description.svg';
import styled from 'styled-components/macro'
import { MEDIA_QUERIES } from '../../constants/constants';

class coreValues extends Component {
    render() {
        return (
            <CoreValues>
                <H3>OUR CORE VALUES</H3>
                <ImageWrapper>
                    <Img className="img-fluid" src={CORE_VALUES} alt="Core Values of ITB" />
                    <DescImg src={CORE_VALUES_DESCRIPTION} alt="Core Values Description of ITB" />
                </ImageWrapper>
            </CoreValues>
        )
    }
}

const Img = styled.img`
    vertical-align: center;
`;

const DescImg = styled.img`
    margin-left: 80px;
    @media ${MEDIA_QUERIES.tabletAndSmaller} {
        display: none
    }
`;

const ImageWrapper = styled.div`
    display: flex;
`;

const CoreValues = styled.div`
    padding-top: 2%;
    padding-bottom: 2%;
    padding-left: 20%;
    padding-right: 20%;
`;

export default coreValues;