const ImageSrcConstants = {
    CALL_ICON: "https://drive.google.com/thumbnail?id=1wMTSLwBviChdG51IKeUmTGR19-mj3DAU",
    IPLANT: "https://drive.google.com/uc?export=view&id=1bP1F5Oa37-wZtCwP16wi8BexcayBChP8",
    LOGO: "https://drive.google.com/thumbnail?id=1l1nxqa-XgWwCtREUbk6ns6N4maIqBUfv",
    SUMMER_CALLING: "https://drive.google.com/uc?export=view&id=1JJBWMTAXfRc0YyuYv2QqShgfabQvm95K",
    VISION: "https://drive.google.com/uc?export=view&id=1ym64dcBtOQQz66hRh1LAc7BvZIsj9mmY",
    VOLUNTEER: "https://drive.google.com/uc?export=view&id=1HWnPMyoPjnPJK-Xmv5Ck9ME_YngT1C7t",
    VOLUNTEER_REG_FORM: "https://docs.google.com/forms/d/1RXTtyoMVVv8OCw85xyaGnfavi3b--glYHgr2cdDbuHM/edit?usp=drivesdk",
    HOME_PAGE: [
        {
            src: "https://drive.google.com/uc?export=view&id=1So8Gumc5vtT1LdaXgbDO7sVb5Xc-8DA9",
            altText: 'Gnyana Jyothi Project',
        },
        {
            src:  "https://drive.google.com/uc?export=view&id=1JS26dR9lvbj1YpW6DhBOoVTM1x9NPJwb",
            altText: 'Kids and Inside The Box Trust',
        },
        {
            src: "https://drive.google.com/uc?export=view&id=1er06BcMhJBH1MP5GXcEuNKew29yCavns",
            altText: 'Scribe Activity',
        },
    ],
    IPLANT_DOODLE: "https://drive.google.com/uc?export=view&id=1e0nCEXk1iTvxTCBSMpdbhp72Zwd5rQ1-",
}

export const CAMPAIGNS =  [
    {
        imageSrc: ImageSrcConstants.SUMMER_CALLING,
        imageAltText: 'Summer Calling',
        name: 'Summer Calling',
        timeline: 'March to July 2021, Bangalore',
        description: `An effort to create awareness about how to develop bird friendly home or land.
        Participate in this activity by keeping a bowl full of water and grains on the terrace or balcony on a regular basis.
        Inspire and spread awareness among family, friends, and community.`,
        moreLink: '/green-initiative'
    },
    {
        imageSrc: ImageSrcConstants.IPLANT,
        name: 'IPlant Initiative',
        timeline: '5th June 2021, Bangalore',
        description: `Small acts when multiplied by millions of people can transform the world.
        To start with, ITB aims to plant 10000 saplings by reaching 2000 people.
        Participate in this activity by growing and taking care of plants at home.`,
        moreLink: '/iplant',
    }
]

export const CAMPAIGNS_DESKTOP =  [
    [{
        imageSrc: ImageSrcConstants.SUMMER_CALLING,
        imageAltText: 'Sparrow drinking water from pot',
        name: 'Summer Calling',
        timeline: 'March to July 2021, Bangalore',
        description: `An effort to create awareness about how to develop bird friendly home or land.
        Participate in this activity by keeping a bowl full of water and grains on the terrace or balcony on a regular basis.
        Inspire and spread awareness among family, friends, and community.`,
        moreLink: '/green-initiative'
    },
    {
        imageSrc: ImageSrcConstants.IPLANT,
        name: 'iPlant Initiative',
        timeline: '5th June 2021, Bangalore',
        description: `Small acts when multiplied by millions of people can transform the world.
        To start with, ITB aims to plant 10000 saplings by reaching 2000 people.
        Participate in this activity by growing and taking care of plants at home.`,
        moreLink: '/iplant',
    }],
]

export default ImageSrcConstants;
