import React, { Component } from 'react';
import { PageHeadingWithCaption, Caption } from '../../components/elements';

class brand extends Component {
  render() {
    return (
      <div>
        <PageHeadingWithCaption>INSIDE THE BOX TRUST</PageHeadingWithCaption>
        <Caption>Impacting lives since April 2015</Caption>
        </div>
    )
  }
}

export default brand;