const advisoryBoardInfo = {
    DATA: [
      {
        name: "SANDEEP H",
        img: "https://drive.google.com/thumbnail?id=17MI216PoyrFURbiWebK_1xfQEdFZRZbo"
      },
      {
        name: "SHARATH M",
        img: "https://drive.google.com/thumbnail?id=1Cn4fur-9umT20rjK049cGMPtYoqZp2VJ"
      },
      {
        name: "PRASHANT A",
        img: "https://drive.google.com/thumbnail?id=1AMplsCbztCU-WBXwtc0mvv9CObkhngh6"
      }
    ]
}

export default advisoryBoardInfo;