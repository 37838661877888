import React, { Component } from 'react';
import Brand from './brand'
import Motto from './motto'
import Mission from './mission'
import Vision from './vision'
import CoreValues from './coreValues'

class itb extends Component {
  render() {
    return (
      <div>
        <Brand />
        <Motto />
        <Vision />
        <Mission />
        <CoreValues />
      </div>
    )
  }
}

export default itb;