import React, { Component } from 'react';
import '../../css/app.css'
import styled from 'styled-components/macro';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Section, PageHeading } from '../../components/elements';
import Doodle from '../../resources/campaigns/iplant/doodle.svg';
import { TOPICS_DESKTOP, TOPICS_MOBILE } from '../../resources/campaigns/iplant/iplant';
import { MEDIA_QUERIES } from '../../constants/constants';
import { H3, Container } from '../../components/elements';
import Button from '../../styles/lib';

class iplant extends Component {
    render() {
        return (
            <Section>
                <PageHeading>IPlant INITIATIVE</PageHeading>
                <Img src={Doodle} alt="Plant and protect plants" />
                <P>ITB has completed many initiatives like cleanup drives, education workshops, etc. The recent one is "IPlant Initiative". Why IPlant initiative? What are its objectives?<br />
                    <b>"Green Home Education"</b> is the main objective of this initiative. Like every education starts at home, planting and protecting should also begin from home. Seedling, sapling, protecting the plants unite family members for a single cause. This can even inculcate natural intelligence in a child. Through this initiative, we can involve children and hope for a green future ahead.<br />
                    <b>"Biowaste reuse"</b> is the second objective of this initiative. Around 60% of our daily waste comes from the kitchen, and if we can't channelise it properly, it proves hazardous to our environment. So composting seems like a solution to urban waste. As composting can lead to a green home, it is an integral part of the IPlant initiative.<br /><br />
                    As beautifully said by someone, "Small acts when multiplied by millions of people can transform the world". ITB aims to plant 10000 saplings by reaching 2000 people in the beginning. As it is a community-related service, We, ITBians, hope everyone joins their hands to make it successful.
                </P>
                <DesktopTopic />
                <MobileTopic />
            </Section>
        )
    }
}

const Topic = ({ topic }) => {
    return (
        <TopicWrapper>
            <div>
                <TopicImage
                    src={topic.img}
                    alt={topic.name}
                />
            </div>
            <TopicContent>
                <TopicName>{topic.name}</TopicName>
                <TopicDescription>{topic.description}</TopicDescription>
                <div style={{marginTop: 'auto'}}><a href={topic.link} rel="noopener noreferrer" target="_blank"><Button variant="secondary" size="medium">Learn More</Button></a></div>
            </TopicContent>
        </TopicWrapper>
    )

}

const DesktopTopic = () => {
    return (
        <DesktopTopicWrapper>
            {
                TOPICS_DESKTOP.map((topic, i) => (
                    <TopicContainer>
                        {
                            topic.map((topicItem) => (
                                <Topic key={topicItem.name} topic={topicItem} />
                            ))
                        }
                    </TopicContainer>
                ))
            }
        </DesktopTopicWrapper>
    );
}

const Img = styled.img`
    height: 60vh;
    @media ${MEDIA_QUERIES.tabletAndSmaller} {
        height: 30vh;
    }
`;

const MobileTopic = () => {
    return (
        <MobileTopicWrapper>
            {TOPICS_MOBILE.map((topic) => (
                <MobileContainer>
                    <Topic topic={topic} />
                </MobileContainer>
            ))}
        </MobileTopicWrapper>
    )
}

const P = styled.p`
    margin-top: 50px;
`;

const TopicContent = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const TopicWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    max-width: 45%;
    @media ${MEDIA_QUERIES.tabletAndSmaller} {
        max-width: 100%;
    }
`;

const DesktopTopicWrapper = styled.div`
    display: block;
    @media ${MEDIA_QUERIES.phone} {
        display: none;
    }
`;

const MobileTopicWrapper = styled.div`
    display: none;
    @media ${MEDIA_QUERIES.phone} {
        display: block;
    }
`;

const TopicDescription = styled.p`
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
    margin-bottom: 1em;
`;

const TopicContainer = styled(Container)`
    flex-direction: row;
    margin-top:60px;
    gap 60px;
`;

const MobileContainer = styled.div`
    margin: 30px 0;
`;

const TopicName = styled(H3)`
  font-size: 1.25rem;
  font-weight: 600;
  @media ${MEDIA_QUERIES.tabletAndSmaller} {
      text-transform: uppercase;
  }
`;

const TopicImage = styled.img`
    width: 100%;
    max-height: 300px;
    object-fit: cover;
    display: block;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
`;

export default iplant;
