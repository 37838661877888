import { React } from 'react';
import styled from 'styled-components/macro';

function Brand() {
    return (
        <BrandWrapper>
            <Img src="/logo.svg" alt="Logo"></Img>
            Inside&nbsp;the&nbsp;Box&nbsp;Trust
        </BrandWrapper>
    )
}

const BrandWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    text-transform: uppercase;
`;

const Img = styled.img`
    width: 2.5rem;
`;

export default Brand;
