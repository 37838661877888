import React from "react";
import styled from "styled-components/macro";
import { H4 } from '../../components/elements';

function DateRange({ startDate, endDate }) {

  return (
    <DateRangeWrapper>
      <H4> {startDate} </H4>
      <To>to</To>
      <H4> {endDate} </H4>
    </DateRangeWrapper>

  );
};

const DateRangeWrapper = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
`;


const To = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 50%;
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
    background: #F02A3E;
    color: white;
    font-style: italic;
`;

export default DateRange;
