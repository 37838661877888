import React, { Component } from 'react';
import styled from 'styled-components/macro'
import NGO_MODEL_IMAGE from '../../resources/about/model/ngo_model.svg';
import '../../css/about/model.css';
import { H3, PageHeading } from '../../components/elements';
import { MEDIA_QUERIES } from '../../constants/constants';

class model extends Component {
    render() {
        return (
            <div>
                <div className="model">
                    <PageHeading>OUR NGO MODEL</PageHeading>
                    <ImgWrapper>
                        <img class="img-fluid" src={NGO_MODEL_IMAGE} alt="NGO model" />
                    </ImgWrapper>
                </div>

                <div className="background">
                    <MobileBlock>
                        <H3>LIFE EDUCATION</H3>
                    </MobileBlock>
                    <Section>
                        <Block>
                            <div className="logo lifeEducation">
                                LIFE EDUCATION
                            </div>
                        </Block>
                        <div class="col-sm-8">
                            <P>Education that helps in adapting to the challenges and demands of everyday life is essential. Compassion for humanity and spreading love should form the essence of life education.
                                Life education covers a broad range of topics. Emotional aspects like self-awareness, sympathy and empathy, creativity, imagination, innovation, coping with stress. Health topics like cleanliness, sanitation, ill effects of alcohol and smoking.
                                Environmental topics like planting trees, cleaning the surroundings, no plastic usage. Soft skills like communication skills, including spoken English.
                            </P>
                        </div>
                    </Section>
                </div>

                <div>
                    <MobileBlock>
                        <H3>KNOWLEDGE BASED EDUCATION</H3>
                    </MobileBlock>
                    <Section>
                        <Block>
                            <div className="logo knowledgeBased">
                                KNOWLEDGE BASED EDUCATION
                            </div>
                        </Block>
                        <div class="col-sm-8">
                            <P>The program “Inside the computing” is a knowledge-based education on a computer with innovative methods.
                                “Inside the sports” is a program to encourage kids in sports.
                                “Inside the Science” is a program to provide knowledge on the implementation of science in fields like farming and tourism.
                                “Inside the Art” is a program to encourage the kids to indulge in the field of art.
                            </P>
                        </div>
                    </Section>
                </div>

                <div className="background">
                    <MobileBlock>
                        <H3>RESEARCH WING</H3>
                    </MobileBlock>
                    <Section>
                        <Block>
                            <div className="logo researchWing">
                                RESEARCH WING
                            </div>
                        </Block>
                        <div class="col-sm-8">
                            <P>This wing works with experts to create course materials.
                                Case studies are used to encourage writing skills, art and discussions.
                                Students are given exposure to inspirational people and events to inspire them.
                                This is also used to encourage them to participate in sports, drama and other non-academic activities.
                                Kids are motivated to prepare their wall magazine for the class. Art, role play, storyboard, music, puzzles, board games and other activities are used to keep kids engaged.
                                Various teaching concepts like multiple intelligence concepts and blooms taxonomy are being implemented. Focus is kept on keeping teaching fun-oriented and activity-based.
                                Brainstorming sessions, speech and debates to inculcate reasoning abilities are used to improvise teaching techniques.
                                Audio and video-based learning are being used for some of the activities. Video-based online teaching for high school and college-going students are planned for the future.
                            </P>
                        </div>
                    </Section>
                </div>

                <div>
                    <MobileBlock>
                        <H3>CREATING LEADERS</H3>
                    </MobileBlock>
                    <Section>
                        <Block>
                            <div className="logo leaders">
                                CREATING LEADERS
                            </div>
                        </Block>
                        <div class="col-sm-8">
                            <P>
                                The leadership program has three pillars - volunteers, teachers and kids.
                                All the volunteers are trained by experts periodically. They are provided with opportunities to excel in their field of interest. Eminent personalities from different fields conduct sessions to inspire the volunteers.
                                Workshops are conducted for teachers to train them in modern teaching methodologies.
                                There are leadership workshops to enhance leadership skills in volunteers and teachers.
                                Essays, debates, music, art and speech competitions are conducted for students. After the workshops, selected students lead the activity for the NGO and even teach lower classes.
                            </P>
                        </div>
                    </Section>
                </div>

                <div className="background">
                    <MobileBlock>
                        <H3>AWARENESS PROGRAMS</H3>
                    </MobileBlock>
                    <Section>
                        <Block>
                            <div className="logo awareness">
                                AWARENESS PROGRAMS
                            </div>
                        </Block>
                        <div class="col-sm-8">
                            <P>
                                Environmental awareness programs like planting trees, no plastic movement, save forest campaign have been conducted.
                                The plan is to conduct awareness programs on health, sanitation and cleanliness.
                            </P>
                        </div>
                    </Section>
                </div>

                <div>
                    <MobileBlock>
                        <H3>MENTORING KIDS AND STUDENTS</H3>
                    </MobileBlock>
                    <Section>
                        <Block>
                            <div className="logo mentoring">
                                MENTORING KIDS AND STUDENTS
                            </div>
                        </Block>
                        <div class="col-sm-8">
                            <P>
                                Tie up with corporates and have the professionals mentor the students on a one on one basis.
                                Utilise online mentoring sessions for students in remote villages. This would involve online classes, video-based classrooms, online assessment and periodic face-face meetings.
                            </P>
                        </div>
                    </Section>
                </div>

                <div className="background">
                    <MobileBlock>
                        <H3>BUILDING INFRASTRUCTURE</H3>
                    </MobileBlock>
                    <Section>
                        <Block>
                            <div className="logo infra">
                                BUILDING INFRASTRUCTURE
                            </div>
                        </Block>
                        <div class="col-sm-8">
                            <P>
                                The current focus is on providing a Remote Library. The idea is to understand the needs of kids, their interests and what motivates them. The plan is to provide the kids with audio, visual and kinesthetic learning capabilities.
                            </P>
                        </div>
                    </Section>
                </div>

                <div>
                    <MobileBlock>
                        <H3>EVALUATION SYSTEM</H3>
                    </MobileBlock>
                    <Section>
                        <Block>
                            <div className="logo evaluation">
                                EVALUATION SYSTEM
                            </div>
                        </Block>
                        <div class="col-sm-8">
                            <P>
                                The objectives, vision, human resource system, financial system and accountability systems are evaluated periodically.
                                There is a periodic evaluation system for the process and progress of the students.
                                The volunteers indicate the metrics after every class. These results are quantified, and actionable items are derived.
                                The performance of the volunteers is evaluated, and they progress to leadership roles based on performance.
                                Donors contribute to the financial evaluation system.
                                A comprehensive feedback system is in place to improve the processes.
                            </P>
                        </div>
                    </Section>
                </div >
            </div >
        )
    }
}

const Section = styled.div`
    display:flex;
    justify-content: center;
    align-items: center;
    padding-top: 3%;
    padding-bottom: 3%;
`;

const Block = styled.div`
    padding-right: 5%;
    @media ${MEDIA_QUERIES.tabletAndSmaller} {
        display: none;
    }
`;

const MobileBlock = styled.div`
    padding-top: 2%;
    padding-left: 5%;
    @media ${MEDIA_QUERIES.tabletAndBigger} {
        display: none;
    }
`;

const ImgWrapper = styled.div`
    padding-top: 2%;
    padding-bottom: 4%;
    padding-left: 3%;
    padding-right: 3%;
`;

const P = styled.p`
    text-align: justify;
    vertical-align: middle;
`;

export default model;