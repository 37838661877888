const GENERAL = {
  QUESTIONS: [
    {
      ques: "What is ITB and its objective?",
      ans: "ITB is the abbreviation for Inside the Box Trust. The objective of ITB is to provide quality life education to the underprivileged children by creating a hands-on learning environment thereby equipping them to build a better and sustainable future.",
    },
    {
      ques: "Which are the areas ITB works in?",
      ans: "Education, children welfare and environment awareness.",
    },
    {
      ques: "What is the source of funding for ITB?",
      ans: "Trustees and Donation Drives. “Hani Hani Kudi Halla” is the concept used for fundraising. Donations are taken in very small amounts unless the donor wants to sponsor or wants to contribute more. The plan is to reach out to more people and make them stakeholders for the cause.",
    },
    {
      ques: "What is the vision of ITB?",
      ans: "Education to Empower and Education to Sustain.",
    },
    {
      ques: "Who are the largest beneficiaries?",
      ans: "Children, parents, teachers and volunteers.",
    },
    {
      ques: "How can one associate with ITB?",
      ans: "One can choose to be a volunteer or a donor. People can even contribute by helping with the information for a project or can share their ideas.",
    },
    {
      ques: "How can I work for ITB?",
      ans: "If you wish to be a volunteer you can fill the volunteer registration <a href=\"https://docs.google.com/forms/d/1RXTtyoMVVv8OCw85xyaGnfavi3b--glYHgr2cdDbuHM/edit?usp=drivesdk\" without rel=\"noopener noreferrer\" target=\"_blank\"> form</a> or contact us at 9686944344.",
    },
    {
      ques: "At present how many lives have been impacted with ITB work?",
      ans: "ITB work has reached 6 schools, 2 orphanages, 1 old age home and more than 1000 kids. Around 100 volunteers have been part of various activities.",
    },
    {
      ques: "How can I be part of donation and what can I donate?",
      ans: "Donations can be provided in the form of books, stationery books, grammar books, note books, pencils, clothes or monetary donations. Direct donation to school also can be done. The volunteers will visit the school and analyse the requirements and communicate the same to the donor. Donors can be a part of our donation drive. No cash donations are accepted. Digital transfers or cheques/DD is accepted. To Donate, please fill out the donation request <a href=\"https://forms.gle/VQAZU3tArYhoG81K6\" without rel=\"noopener noreferrer\" target=\"_blank\"> form</a>. Once the donation request is made, you will be contacted within 48 hours to further process your request. Reach us at 96869 44344 to get more information.",
    },
    {
      ques: "How are these donations used? Who is it donated to?",
      ans: "The donation will be used to fuel our activities to reach our goal for children from economically poor backgrounds. Specifically, it will be used in teaching activities and conducting competitions. Donated items are either donated directly to the kids or used as prizes for activities.",
    },
    {
      ques: "How does ITB work for education of the underprivileged children?",
      ans: "ITB focuses more on life skills and soft skills along with knowledge oriented education. ITB has its own course materials and has a research team to plan activities for kids.",
    }
  ],
}

export default GENERAL;