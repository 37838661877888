import { Menu, MenuButton, MenuItem, MenuList } from '@reach/menu-button';
import { React } from 'react';
import styled from 'styled-components/macro';

import { COLORS, MEDIA_QUERIES, WEIGHTS } from '../../constants/constants';

function DropdownMenu({title, children}) {

    return (
        <>
            <DropdownMenuListWrapper>
                <Menu>
                    <MenuButtonWrapper >
                        {title}&nbsp;<span aria-hidden>▾</span>
                    </MenuButtonWrapper>
                    <DropdownMenuList>
                        { children.map((menuItem, i) => (
                            <MenuItemWrapper key={i} onSelect={() => {}} className="menu-item">{menuItem}</MenuItemWrapper>
                        )) }
                    </DropdownMenuList>
                </Menu>
            </DropdownMenuListWrapper>
            <DropdownMenuMobile>
              <summary>{ title }</summary>
              <MobileItems>{ children }</MobileItems>
            </DropdownMenuMobile>
        </>
    );
}

const DropdownMenuListWrapper = styled.div`
    position: relative;
    cursor: pointer;
    font-size: 0.875rem;
    text-decoration: none;
    color: ${COLORS.gray[900]};
    font-weight: ${WEIGHTS.medium};
    &:hover {
        text-decoration: none;
        color: ${COLORS.gray[900]};
    }
    @media ${MEDIA_QUERIES.tabletAndSmaller} {
        display: none;
    }
`;

const MenuButtonWrapper = styled(MenuButton)`
    background-color: inherit;
    border: none;
    font-weight: 600;
    color: ${props => props.isOpen ? `${COLORS.primary}` : 'inherit'};
    &:hover {
        color: ${COLORS.primary};
    }
`;

const DropdownMenuList = styled(MenuList)`
    background-color: #fff;
    margin-top: 25px;
    color: red;
    > [data-reach-menu-item][data-selected] {
        color: ${COLORS.gray[900]};
    }
    @media ${MEDIA_QUERIES.tabletAndSmaller} {
        position: relative;
    }
`

const MenuItemWrapper = styled(MenuItem)`
    padding: 10px;
    padding-right: 50px;
    border-bottom: 1px solid ${COLORS.gray[100]};
`;

const DropdownMenuMobile = styled.details`
    display: none;
    font-size: 1rem;
    color: ${COLORS.gray[900]};
    font-weight: ${WEIGHTS.medium};


    @media ${MEDIA_QUERIES.tabletAndSmaller} {
        display: flex;
        flex-direction: column;
    }
`;

const MobileItems = styled.div`
    margin: 10px 0 0 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export default DropdownMenu;
