import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import ImageSrcConstants from '../../constants/ImageSrcConstants';
import { H2, Section, SectionContents } from '../../components/elements';
import Button from '../../styles/lib';
import { MEDIA_QUERIES } from '../../constants/constants';

class WhoAreWe extends Component {
  render() {
    return (
      <Section>
        <H2>Inside The Box Trust - Who are we?</H2>
        <SectionContents>
          <Logo>
            <img src={ImageSrcConstants.LOGO} alt="Inside the box trust logo" /><br></br>
            <h5>Unwrapping the NextGen</h5>
          </Logo>
          <div>
            <p>Here at Inside The Box Trust, we believe in joining hands with multiple stakeholders to bring positive changes in the education system. We focus more on life skills which can help children to deal with the challenges and demands of everyday life.  We want to teach and create awareness with a lot of love and passion. We believe in Vishwa Manava principles advocated by Rashtra Kavi Kuvempu and we work as a team guided by the core values of our organization. We want to be the process-driven organization that believes in creating an environment where people can teach and learn and take some positives out of working here.</p>
            <Button styles={{marginTop: '20px'}} variant="secondary"><Link to="/who-we-are">LEARN MORE</Link></Button>
          </div>
        </SectionContents>
      </Section>
    )
  }
}

const Logo = styled.div`
    min-width: 20rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media ${MEDIA_QUERIES.tabletAndSmaller} {
        display: none;
    }
`;

export default WhoAreWe;
