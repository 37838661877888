export const COLORS = {
    white: 'hsl(0deg 0% 100%)',
    black: 'hsl(0, 0%, 0%)',
    gray: {
        100: 'hsl(185deg 5% 95%)',
        300: 'hsl(190deg 5% 80%)',
        500: 'hsl(196deg 4% 60%)',
        700: 'hsl(220deg 5% 40%)',
        900: 'hsl(220deg 3% 20%)',
    },
    primary: 'hsl(354deg, 87% , 55%)',
    secondary: 'hsl(240deg 60% 63%)',
    dark: 'hsl(0,0%,20%)',
};

export const FONT_SIZES = {
    normal: '1',
    small: '0.875',
    xs: '0.70',
}

export const WEIGHTS = {
    normal: 500,
    medium: 600,
    bold: 800,
};

export const BREAKPOINTS = {
    phone: 37.5,
    tablet: 59.375,
    bigTablet: 68.75,
    laptop: 81.25,
}

export const MEDIA_QUERIES = {
    phone: `(max-width: ${BREAKPOINTS.phone}rem)`,
    tabletAndSmaller: `(max-width: ${BREAKPOINTS.tablet}rem)`,
    bigTabletAndSmaller: `(max-width: ${BREAKPOINTS.bigTablet}rem)`,
    laptopAndSmaller: `(max-width: ${BREAKPOINTS.laptop}rem)`,
    tabletAndBigger: `(min-width: ${BREAKPOINTS.tablet}rem)`,
}

export const SOCIAL_LINKS = {
    fb: 'https://www.facebook.com/insidetheboxngo',
    twitter: 'https://twitter.com/insidetheboxN',
    instagram: 'https://instagram.com/insidethebox_ngo',
}
