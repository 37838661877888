import styled from 'styled-components/macro'
import React, { Component } from 'react';
import { H3 } from '../../components/elements';
import VISION_IMAGE from '../../resources/about/itb/vision.svg';

class vision extends Component {
    render() {
        return (
            <Vision>
                <H3>OUR VISION</H3>
                <img className="img-fluid" src={VISION_IMAGE} alt="Education to empower and sustain." />
            </Vision>
        )
    }
}

const Vision = styled.div`
    padding-top: 2%;
    padding-bottom: 2%;
    padding-left: 20%;
    padding-right: 20%;
`;

export default vision;