import React, { Component } from 'react';
import ReactPlayer from "react-player";
import events from '../../resources/campaigns/greenInitiative';
import { PageHeading } from '../../components/elements';
import '../../css/app.css'
import styled from 'styled-components/macro';
import CampaignHeader from './campaignHeader';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import ItbCarousel from '../itb-carousel';
import { MEDIA_QUERIES } from '../../constants/constants';

class greenInitiative extends Component {
  render() {
    const backgroundColor = ['#FFFFFF', '#EFEFEF'];

    return (
      <div>
        <PageHeading>GREEN INITIATIVE</PageHeading>

        {events.EVENTS.map((event, index) => (
          <Campaign>
            <Event style={{
              background: backgroundColor[index % backgroundColor.length]
            }}>

              <CampaignHeader event={event} />

              <ItbCarousel autoplay={true}>
                {event.img.map((image, i) => (
                  <div
                    key={image.src}
                  >
                    <Img
                      src={image.src}
                      alt={image.altText}
                    />
                  </div>
                ))}

                {event.videos.map((video, i) => (
                  <Video
                    key={video}
                  >
                    <ReactPlayer object-fit='cover' height='100%' width='100%' url={video} />
                  </Video>
                ))}

              </ItbCarousel>

              <Content>{event.content}</Content>

            </Event>
          </Campaign>
        ))}

      </div>
    )
  }
}

const Img = styled.img`
    height: 60vh;
    width: 100%;
    object-fit: cover;
    @media ${MEDIA_QUERIES.tabletAndSmaller} {
      height: 40vh;
  }
`;

const Video = styled.div`
    height: 60vh;
    width: 100%;
    object-fit: cover;
    background-color: black;
    @media ${MEDIA_QUERIES.tabletAndSmaller} {
      height: 40vh;
  }
`;

const Campaign = styled.div`
    display: flex;
`;

const Event = styled.div`
    padding-top: 2%;
    padding-bottom: 2%;
    padding-left: 20%;
    padding-right: 20%;
    @media ${MEDIA_QUERIES.bigTabletAndSmaller} {
      padding-top: 10vw;
      padding-bottom: 10vw;
      padding-left: 5%;
      padding-right: 5%;
  }
`;

const Content = styled.div`
  margin-top: 20px;
  white-space: pre-wrap;
`;

export default greenInitiative;
