const events = {
  EVENTS: [
    {
      place: "Online singing and dancing competition",
      dateType: "month",
      month: "July",
      year: "2020",
      img: [
        {
          src: "https://drive.google.com/uc?export=view&id=13fNltzJSKxyIwqR_YCpYwmuZ7wbC5_oy",
          altText: "Online singing and dancing competition",
        },
        {
          src: "https://drive.google.com/uc?export=view&id=13i1P0gpWt4LJjmK3mpvSa41sF8ioiCDa",
          altText: "Online singing and dancing competition",
        },
      ],
      videos: [
      ],
      content: "With the onset of the pandemic, the children were forced to spend more time in front of screens rather than playing outside. Any art form like music or dance instils kids to be curious and imaginative. By communicating freely with their voice, face and body, children learn to express ideas with confidence, empathise with others from different cultures and backgrounds and feel at home in their skin. This creative outlet can also give them opportunities to learn about other cultures through the arts. The meaning of music or dance is not dependent on language. To give an opportunity for kids to showcase and express their talent, an online music and dance competition was organized for them. 33 kids participated in singing and 31 kids participated in the dancing competition.",
    },
    {
      place: "Online story narration competition",
      dateType: "month",
      month: "July",
      year: "2020",
      img: [
      ],
      videos: [
      ],
      content: "Story narration is a life skill in itself. It forms an integral part of life skills education. The more positivity one finds in their surroundings, the more one's life will be beautiful. Story narration competition was organized for the kids to narrate a story through different modes like reciting the story or expressing the story through artwork. 23 kids participated in this activity.",
    },
    {
      place: "Creative Day at School",
      dateType: "year",
      year: "2019",
      img: [
        {
          src: "https://drive.google.com/uc?export=view&id=1HBA27TYH_4yv-7ogz5mYtECu3ue-10tw",
          altText: "Kids learning at school",
        },
        {
          src: "https://drive.google.com/uc?export=view&id=1jMv6hMyOe7q-_S2xHqKylkXwid9FKWak",
          altText: "Happy Kids",
        },
        {
          src: "https://drive.google.com/uc?export=view&id=1GS_9P_2nMj-8UCEyvzVluCIXi1DsoQqB",
          altText: "The team",
        },
      ],
      videos: [
      ],
      content: "At Lalaghatta school in Channapatna, a creative day was organized for the students. Kids were encouraged to express their creativity. Activities like writing a letter to nature, extempore on environmental topics were used to educate the kids about the importance of the environment. The students were involved in Kasadinda Rasa-\"Treasure out of Trash\". The kids were taught the value of trees in our lives and were involved in planting activities.",
    },
  ],
}

export default events;