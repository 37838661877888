import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { DialogOverlay, DialogContent } from '@reach/dialog';


import Icon from '../styles/Icon';
import UnstyledButton from '../styles/UnstyledButton';
import { NavLink } from '../components/elements';
import SocialIcons from '../components/social-icons';

const MobileMenu = ({ isOpen, onDismiss, children }) => {
  return (
    <Overlay isOpen={isOpen} onDismiss={onDismiss}>
      <Content aria-label="Menu">
        <CloseButton onClick={onDismiss}>
          <Icon id="close" />
        </CloseButton>
        <Filler />
        { children }
        <Footer>
          <NavLink as={Link} onClick={onDismiss} to="/faq">FAQ</NavLink>
          <NavLink as={Link} onClick={onDismiss} to="/tnc">Terms and Conditions</NavLink>
          <NavLink as={Link} onClick={onDismiss} to="/privacy">Privacy Policy</NavLink>
          <SocialIcons/>
        </Footer>
      </Content>
    </Overlay>
  );
};

const Overlay = styled(DialogOverlay)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: hsl(220deg 5% 40% / 0.8);
  display: flex;
  justify-content: flex-end;
`;

const Content = styled(DialogContent)`
  background: white;
  width: 300px;
  height: 100%;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const CloseButton = styled(UnstyledButton)`
  position: absolute;
  top: 10px;
  right: 0;
  padding: 16px;
`;

const Filler = styled.div`
  min-height: 20px;
  max-height: 20px;
`;
const Footer = styled.footer`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 10px;
`;

export default MobileMenu;
