import React, { Component } from 'react';
import HeroImages from './heroImages'
import WhoAreWe from './who-are-we'
import Campaigns from './campaigns'
import Volunteer from './volunteer'

class Home extends Component {
  render() {
    return (
      <div>
        <HeroImages />
        <WhoAreWe />
        <Volunteer />
        <Campaigns />
      </div>
    )
  }
}

export default Home;
