import React, { Component } from 'react';
import { Section, H3, PageHeading } from '../../components/elements';

class privacy extends Component {
    render() {
        return (
            <Section>
                <PageHeading>PRIVACY POLICY</PageHeading>

                <div className="content">
                    <p>Protecting your privacy is important for Inside The Box Trust and all access details will be
                        kept strictly confidential. The site may, however, use this information from time to time for purposes such as corresponding with the users for various issues, etc. We shall duly seek permission of the users in such cases.</p>

                    <p>The following policy provides information on how we collect, use, and safeguard the
                        personal information you provide on our Website. This policy may change from time to
                        time. Please reread this policy periodically.</p>

                    <p>When you browse our website www.insidetheboxtrust.org, you do so anonymously. We do not collect personal information except when you register as a volunteer, donor or ask any queries.</p>

                    <p>For any queries please reach out to us via email at insidetheboxtrust.contact@gmail.com</p><br />

                    <H3>Rules and Regulations for Volunteer</H3>
                    <p>- No cash donations may be collected by any volunteer. As far as possible, motivate people to
                        send their donations via digital means or directly to ITB.<br />
                        - Donors can donate stationeries. Volunteers are not permitted to use ITB stationery as their own.<br />
                        - All ITB literature will be developed by ITB. Any modification is subject to prior approval by ITB.<br />
                        - Volunteers may not release any material pertaining to ITB in the media (TV/
                        radio/magazines/newspapers/newsletters etc.), without seeking prior approval from ITB.<br />
                        - All ITB activities should be coordinated through the contact point in ITB. Please do not directly
                        interface on projects and/or with other departments of ITB.<br />
                        - Volunteers should not have any criminal case registered.<br />
                        - Volunteers should avoid political and religious discussions in the classroom and meetings.
                    </p>
                </div>

            </Section>
        )
    }
}

export default privacy;