import styled from 'styled-components/macro'
import React, { Component } from 'react';
import { H3 } from '../../components/elements';

class motto extends Component {
    render() {
        return (
            <Motto>
                <H3>OUR MOTTO</H3>
                <P>Education should reach everyone and empower them to realise what is good and bad for them. It should cultivate humanity and humility, love for nature and people, team work and all those good qualities which makes one educated. </P>
            </Motto>
        )
    }
}

const Motto = styled.div`
    background-color: #EFEFEF;
    padding-top: 2%;
    padding-bottom: 2%;
    padding-left: 20%;
    padding-right: 20%;
`;

const P = styled.div`
    text-align:justify;
`;

export default motto;