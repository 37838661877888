import React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { CAMPAIGNS, CAMPAIGNS_DESKTOP } from '../../constants/ImageSrcConstants';
import Button from '../../styles/lib';
import { H2, H3, Container } from '../../components/elements';
import { MEDIA_QUERIES } from '../../constants/constants';
import ItbCarousel from '../itb-carousel';

function Campaigns() {

    const getLearnMoreButton = (isExternal, link) => {
        return isExternal
            ? <a href={link} rel="noopener noreferrer" target="_blank"><Button variant="secondary" size="medium">Learn More</Button></a>
            : <Link to={link}><Button variant="secondary" size="medium">Learn More</Button></Link>;
    }

    const Campaign = ({campaign}) => {
        return (
          <CampaignWrapper>
            <div>
                <Img
                    src={ campaign.imageSrc }
                    alt={ campaign.imageAltText}
                />
            </div>
            <CampaignContent>
              <CampaignName>{ campaign.name }</CampaignName>
              <Timeline>{ campaign.timeline }</Timeline>
              <CampaignDescription>{ campaign.description }</CampaignDescription>
              <div style={{marginTop: 'auto'}}>{ getLearnMoreButton(campaign.externalLink, campaign.moreLink) }</div>
            </CampaignContent>
          </CampaignWrapper>
        )

    }

    const CampaignContent = styled.div`
        padding: 20px;
        display: flex;
        flex-direction: column;
        height: 100%;
    `;

    const DesktopCampaign = ({campaigns}) => {
        return (
            <DesktopCampaignWrapper>
                <ItbCarousel>
                    {
                        campaigns.map((campaign, i) => (
                            <CarouselItem key={i}>
                                <CampaignContainer>
                                    {
                                        campaign.map((campaignItem) => (
                                            <Campaign key={campaignItem.name} campaign={campaignItem} />
                                        ))
                                    }
                                </CampaignContainer>
                            </CarouselItem>
                        ))
                    }
                </ItbCarousel>
            </DesktopCampaignWrapper>
        );
    }

    const MobileCampaign = (campaign) => {
        return (
            <MobileCampaignWrapper>
                <ItbCarousel showThumbs={false} showStatus={false}>
                    { CAMPAIGNS.map((campaign) => (
                        <CarouselItem key={campaign.name}>
                            <Campaign campaign={campaign} />
                        </CarouselItem>
                    )) }
                </ItbCarousel>
            </MobileCampaignWrapper>
        )
    }

    return (
          <CampaignSection>
            <Container><H2>Our Recent Campaigns</H2></Container>
            <>
                <DesktopCampaign campaigns={CAMPAIGNS_DESKTOP} />
                <MobileCampaign campaigns={CAMPAIGNS} />
            </>
          </CampaignSection>
      )
}

const CampaignSection = styled.section`
    margin-top: 32px;
    @media ${MEDIA_QUERIES.tabletAndSmaller} {
        padding: 16px;
    }
`;

const CampaignContainer = styled(Container)`
    flex-direction: row;
    gap 20px;
`;
const CarouselItem = styled.div`
    margin: 10px;
    display: flex;
    gap: 20px;
    margin-bottom: 50px;
`;

const CampaignName = styled(H3)`
  font-weight: 600;
  @media ${MEDIA_QUERIES.tabletAndSmaller} {
      text-transform: uppercase;
  }
`;

const Img = styled.img`
    width: 100%;
    max-height: 300px;
    object-fit: cover;
    display: block;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
`;

const CampaignWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
}
`

const DesktopCampaignWrapper = styled.div`
    display: block;
    @media ${MEDIA_QUERIES.phone} {
        display: none;
    }
`;

const MobileCampaignWrapper = styled.div`
    display: none;
    @media ${MEDIA_QUERIES.phone} {
        display: block;
    }
`;

const CampaignDescription = styled.p`
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
    margin-bottom: 1em;
`;

const Timeline = styled.h5`
    font-weight: 600;
    margin-bottom: 5px;
`




export default Campaigns;
