import React, { Component } from 'react';
import { PageHeadingWithCaption, H3 } from '../../components/elements';
import HOW_IMAGE from '../../resources/donate/how.svg';
import WHAT_IMAGE from '../../resources/donate/what.svg';
import WHERE_IMAGE from '../../resources/donate/where.svg';
import styled from 'styled-components/macro';
import { MEDIA_QUERIES } from '../../constants/constants';

class donate extends Component {
    render() {
        return (
            <DonateWrapper>
                <DonateTitle>
                    <PageHeadingWithCaption>DONATE</PageHeadingWithCaption>
                    <p>Contribute to make a difference to lives.</p>
                </DonateTitle>

                <Block>
                    <Description>
                        <H3>How are the donations used?</H3>
                        <p>Your donation will be used to fuel our activities to reach our goal for children from economically poor backgrounds. Funds and aid are used for government schools and private aided schools.
                            Specifically, it will be used in teaching activities and conducting competitions.
                            Donated items are either donated directly to the kids or used as prizes for activities.</p>
                    </Description>
                    <div className="col-md=6">
                        <Img src={HOW_IMAGE} alt="How are donations used?" />
                    </div>
                </Block>

                <Block>
                    <div className="col-md=6">
                        <Img src={WHAT_IMAGE} alt="What donations are accepted?" />
                    </div>
                    <Description>
                        <H3>What donations are accepted?</H3>
                        <p>School text books, grammar books, stationery items like note books, pencils, clothes and monetary funds. Direct donation to school can be made.
                            The ITB team will go to the school, analyse the situation and update the donors regarding the requirements.</p>
                    </Description>
                </Block>

                <Block>
                    <Description>
                        <H3>Where can the donations be made?</H3>
                        <p>To donate please fill out the donation request
                            <a href="https://forms.gle/VQAZU3tArYhoG81K6" without rel="noopener noreferrer" target="_blank"> form</a>. Once the donation request is made, you will be contacted within 48 hours to further process your request.
                            The pick up for donation items is limited to Bangalore, India.
                            During the followup, further details on drop point or pickup will be confirmed. For more enquiries, please reach out to Santhosh on +91 96869 44344 or Shivraj on +91 70196 16249.</p>
                    </Description>
                    <div className="col-md=6">
                        <Img src={WHERE_IMAGE} alt="Where can the donations be made?" />
                    </div>
                </Block>
            </DonateWrapper>
        )
    }
}

const DonateWrapper = styled.div`
    padding-left:18vw;
    padding-right:18vw;
    @media ${MEDIA_QUERIES.bigTabletAndSmaller} {
        max-width: 100vw;
        width: 100vw;
        padding: 0 16px;
    }
}
`
const Block = styled.div`
    display: flex;
    padding-top: 3vw;
    padding-bottom: 3vw;
    align-items: center;
`;

const Description = styled.div`
    display: flex;
    flex-direction: column;
`;

const DonateTitle = styled.div`
    text-align: center;
    padding-top:5%;
    padding-bottom:5%;
}
`

const Img = styled.img`
    padding-left : 2vw;
    padding-right : 2vw;
    @media ${MEDIA_QUERIES.tabletAndSmaller} {
        display: none
    }
}
`

export default donate;