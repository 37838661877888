const events = {
  EVENTS: [
    {
      place: "Summer Calling",
      dateType: "dateRange",
      startDate: "March 2021",
      endDate: "July 2021",
      img: [
      ],
      videos: [
      ],
      content: "Summer Calling is an effort to create awareness about how one can maintain their home or land bird-friendly. Birds are an important part of the ecosystem. Birds are key for seed propagation, they have been essential seed dispensers for plants that provide humans with food, medicine, timber, and recreation. They have helped to shape plant life. Birds are a part of the food chain, keep check of the overpopulation of rodents, insects, and even snakes. Small birds eat up the insects and their larva, and large birds like eagle hunt and kill rodents and snakes for food. Birds act as natural scavengers, help keep nature clean of dead and decay matter. Thus, greatly helpful for humans and nature. Birds fascinate humans with their behaviour, intelligence, and with their songs. "+
      "Millions of birds are directly or indirectly killed by human activities such as collisions with man-made structures, high-frequency technologies, industrial farming, logging, hunting and trapping, climate change, starving, and lack of drinking water."+
      "There is a dire need to mitigate this and restore their habitat and protect them."+
      "India has been a home for many rare bird species from around the world for ages. But unfortunately, thousands of native and migratory birds die every summer due to scorching heat and lack of drinking water."+
      "Summer calling events focus on bringing back the birds to us by making our home and surroundings bird-friendly by providing them the essentials such as food, water and the materials for building their nest."+

      "\n\nHow to participate:\n1. Keep a bowl full of water and grains on the terrace or balcony regularly.\n"+
      "2. Involve family members and kids in this activity. Explain to them the importance of birds in our lives.\n"+
      "3. Spread awareness among family, friends, and community and inspire others.\n"+
      "4. Share experiences and stories in the form of photos, videos, and text on Facebook or @insidethebox_ngo on Instagram.\n",
    },
    {
      place: "DD Hills Cleanup Drive",
      dateType: "year",
      year: "2021",
      img: [
        {
          src: "https://drive.google.com/uc?export=view&id=12pxrOH8LJp6hmnlcyMeR2iXiy4z5JJcP",
          altText: "Cleanup drive",
        },
        {
          src: "https://drive.google.com/uc?export=view&id=1Gtof4cIPezHD45c4bD1MM7bu8RR5NNDg",
          altText: "Garbage collected",
        },
        {
          src: "https://drive.google.com/uc?export=view&id=1H91JbR17NRB6tjgEl2TI2Ha6jDPSA-hs",
          altText: "Green Warriors",
        },
        {
          src: "https://drive.google.com/uc?export=view&id=1GylFVJGXC0Js0xCyvPNIJVLB4HfK4f6J",
          altText: "Cleanup activity",
        },
      ],
      videos: [
      ],
      content: `"Be a part of SOLUTION, Not a part of POLLUTION". With that slogan, the second cleanup drive was announced. Devarayana Durga also known as DD hills is both a religious and tourist destination. It houses different varieties of plants including some of the medicinal plants. With its mesmerizing beauty and beautiful weather, it has attracted a lot of tourists from different parts of the country. But it has also led to plastic pollution. It is really sad to see such a beautiful place ending up like this. As responsible citizens of this country and nature lovers, ITB volunteers organized a cleanup and awareness campaign in DD hills. Over 45+ volunteers participated and over 110+ bags of plastic trash were collected. The plastic trash was transported by Municipality with the help of temple authorities.`,
    },
    {
      place: "Kodachadri Trek Route Cleanup Drive",
      dateType: "year",
      year: "2019",
      img: [
        {
          src: "https://drive.google.com/uc?export=view&id=111FruFIeMbbC7YzYTDfyFdA7W067TS67",
          altText: "Cleanup drive",
        },
        {
          src: "https://drive.google.com/uc?export=view&id=124oVhoPyJswzt1M5y6zI56Do4rAMN-KN",
          altText: "ITB team",
        },
      ],
      videos: [
      ],
      content: "This was the first activity in the green initiative motivated by a video of Kodachari's plastic dump by one of the core team members. Creating awareness about keeping the environment safe from non-biodegradable wastes has been the primary objective of the green initiative. Twelve adults, a nine-year-old kid and a three-year-old kid participated in this cleanup drive. Having two kids made the entire experience fun and motivational. They collected about ten bags of garbage. The idea was to clear the plastic dumped by tourists and spread awareness among them to travel responsibly.",
    },
  ],
}

export default events;