const events = {
  EVENTS: [
    {
      place: "Gottigere Project",
      dateType: "year",
      year: "2019",
      img: [
        {
          src: "https://drive.google.com/uc?export=view&id=1CWMym8CwFagB5h7GjlYryZ7Ki2-6pmnx",
          altText: "Teaching activity",
        },
        {
          src: "https://drive.google.com/uc?export=view&id=1Ebj-Ebdhoi9E1mYJrrfqimjN1Vd__Wq-",
          altText: "Kids at government school",
        },
        {
          src: "https://drive.google.com/uc?export=view&id=1HHBYxy3HEefu7R_QB-nTUg3MCZ0kr2Q_",
          altText: "Kids learning at school",
        },
      ],
      videos: [
      ],
      content: "Over 80 students were educated as a part of this program. The objective was to ensure that a minimum of 40 students can read, write and speak in English. Other students should be keen to learn and show improvement. The kids were motivated and interested in the activities. Soft skills, drawing competition and letter to nature were a few of the activities conducted. There was a significant improvement in the kids. The kids were divided into 4 sections based on their performance. They were regularly evaluated and moved between the sections as necessary.",
    },
    {
      place: "Scribe Activity",
      dateType: "year",
      year: "2019",
      img: [
        {
          src: "https://drive.google.com/uc?export=view&id=1xopLdc4S91p6b8V_7Hc8PNUMX2gzd6D_",
          altText: "Blind school exam",
        },
      ],
      videos: [
      ],
      content: `"Blindness has not been for me a total misfortune; it should not be seen in a pathetic way. It should be seen as a way of life: one of the styles of living.", said Jorge Louis Borge. As a part of this initiative, the volunteers wrote the exams on behalf of the blind students. The children were motivated and enthusiastic. The kids dictated the answers and our volunteers helped by writing the answers for them. The volunteers expressed a feeling of content after interacting with those beautiful and innocent souls. The willingness to learn and humbleness portrayed by the kids will help them be successful and independent in life.`,
    },
    {
      place: "St Theresa",
      dateType: "dateRange",
      startDate: "2017",
      endDate: "2018",
      img: [
        {
          src: "https://drive.google.com/uc?export=view&id=1Vj1_Uwe7TImrzmmo5TIMXdNUyWxKDZdF",
          altText: "Kids learning at school",
        },
        {
          src: "https://drive.google.com/uc?export=view&id=11qBGwqh6zGhOEa0YclOo7xdlyqCZ-oYU",
          altText: "Teaching at school",
        },
        {
          src: "https://drive.google.com/uc?export=view&id=1lU69_MACRhMkRM_mSJa9ZmuHwr9Fgn6a",
          altText: "Learning at school",
        },
      ],
      videos: [
      ],
      content: "While teaching at St Theresa, level 0 course materials were created. Level 0 course focused on phonics and alphabets. Demo sessions were conducted for primary kids especially 5th standard kids. Later in 2018, the teachers requested to help 10th class students with Mathematics revision. The team helped the students with the basics and their syllabus.",
    },
    {
      place: "Prep Talk by Sourab Potdar",
      dateType: "year",
      year: "2017",
      img: [
      ],
      videos: [
      ],
      content: "Sourab Potdar, who took a sabbatical to work for SBI Youth for India fellowship took a session for the volunteers. He spoke about his impactful journey and motivated the volunteers to never give up.",
    },
    {
      place: "Volunteer Training Session",
      dateType: "year",
      year: "2017",
      img: [
      ],
      videos: [
      ],
      content: "The volunteer training session was focused on three main aspects. The first session focused on self-awareness and setting personal goals for growth. Interacting with knowledgeable people and kids while volunteering can help people achieve their goals. The second aspect was learning new interactive ways of teaching kids and understanding children better. The third was about Bloom's taxonomy which helped in setting the objectives for the course materials.",
    },
    {
      place: "Gnyana Jyothi Project",
      dateType: "dateRange",
      startDate: "June 2015",
      endDate: "Oct 2016",
      img: [
        {
          src: "https://drive.google.com/uc?export=view&id=1p7Fs7VUkl0fbQXBu9jeDbF34e8FJq9pL",
          altText: "Primary school kids",
        },
        {
          src: "https://drive.google.com/uc?export=view&id=1-7zD-xOt_JQ1r_lP6dKJ-t2gkRWrVOr6",
          altText: "Students and volunteers",
        },
        {
          src: "https://drive.google.com/uc?export=view&id=1WRn0AaT-3Mfd2I5g1VNrAuit3QKTXvL6",
          altText: "Motivated kids",
        },
      ],
      videos: [
        "https://www.youtube.com/watch?v=L0O-VIiSpIA",
      ],
      content: "This project aimed at teaching the kids soft skills and life skills. Being one of the early projects in the teaching domain, it helped kickstart the course materials. Kids were involved in activities like drawing and speech competition. Significant improvement was seen in kids by the end of the project. Storybooks and stationeries were donated to the kids.",
    },
  ],
}

export default events;