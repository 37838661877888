const trusteesInfo = {
    DATA: [
      {
        name: "SANTHOSH KUMAR",
        designation: "CHAIRMAN",
        img: "https://drive.google.com/thumbnail?id=1AS3R77OgtI8hy3D_U7vWvitjTILdH_be"
      },
      {
        name: "MITHUN MURTHY",
        designation: "VICE CHAIRMAN",
        img: "https://drive.google.com/thumbnail?id=1BGuBfZYquulg2UmVjoi4nBxcYSDYINBC"
      },
      {
        name: "LAXMI POTDAR",
        designation: "OPERATION HEAD",
        img: "https://drive.google.com/thumbnail?id=1WEMP-fitSieds4HX8OQR-8qvo-N23LlB"
      },
      {
        name: "YOGMAYA",
        designation: "RESEARCH HEAD",
        img: "https://drive.google.com/thumbnail?id=1R-E-XtS9BPnKaoObRWiYQ5u0q_3wl2La"
      },
      {
        name: "LAXMI SHEELA",
        designation: "PROJECT AQUISITION HEAD",
        img: "https://drive.google.com/thumbnail?id=19Mdz5kK7wJJHCo6w_U42OcdNLQ7Y8Fk3"
      },
      {
        name: "KANCHANA",
        designation: "GREEN INITIATIVE HEAD",
        img: "https://drive.google.com/thumbnail?id=1b789WCwI2DIgSk01KNYzsv0he3ZIIfW5"
      },
      {
        name: "SRIDEVI",
        designation: "FINANCE HEAD",
        img: "https://drive.google.com/thumbnail?id=1CovgwrBKT1GrA01XIJMlV2KOUar-sigl"
      },
      {
        name: "NIDHISHREE",
        designation: "HR AND MARKETING HEAD",
        img: "https://drive.google.com/thumbnail?id=1o6IvLM4nNJWLsrHYIB8h0772KaWxjDb5"
      }
    ]
}

export default trusteesInfo;