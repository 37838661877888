import React from "react";
import styled from "styled-components/macro";
import { H3MinMargin } from '../../components/elements';

function Month({ month, year }) {

  return (
    <MonthWrapper>
      <H3MinMargin> {month} </H3MinMargin>
      <Line />
      <H3MinMargin>{year}</H3MinMargin>
    </MonthWrapper>

  );
};

const MonthWrapper = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
`;

const Line = styled.div`
    width: 50px;
    height: 3px;
    background: #F02A3E;
    margin-left: 3px;
`;

export default Month;
