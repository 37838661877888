import { Switch, Route, Redirect } from 'react-router-dom';

import Header from './header';
import Footer from './footer';
import Home from './home/home';
import Team from './about/team';
import ITB from './about/itb';
import Model from './about/model'
import IPlant from './campaign/iplant'
import GreenInitiative from './campaign/greenInitiative'
import DonationDrive from './campaign/donationDrive'
import Shiksha from './campaign/shiksha'
import ArtsAndCreativity from './campaign/artsAndCreativity'
import '../css/app.css'
import donate from './donate/donate';
import FAQ from './faq/faq';
import Privacy from './privacy/privacy';
import TnC from './tnc/tnc';
import styled from 'styled-components';

function App() {
  return (
    <div>
        <Header />
        <Main>
            <Switch>
            <Route path="/home" component={Home} />
            <Route path="/who-we-are" component={ITB} />
            <Route path="/ngo-model" component={Model} />
            <Route path="/team" component={Team} />
            <Route path="/iplant" component={IPlant} />
            <Route path="/green-initiative" component={GreenInitiative} />
            <Route path="/donation-drive" component={DonationDrive} />
            <Route path="/shiksha" component={Shiksha} />
            <Route path="/arts-and-creativity" component={ArtsAndCreativity} />
            <Route path="/donate" component={donate} />
            <Route exact path="/">
                <Redirect to="/home" />
            </Route>

            <Route path="/faq" component={FAQ} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/tnc" component={TnC} />
            </Switch>
        </Main>
        <Footer />
    </div>
  );
}

const Main = styled.main`
    min-height: 100vh;
`;
export default App;
