import styled from "styled-components/macro";
import { Carousel } from 'react-responsive-carousel';

import { COLORS, MEDIA_QUERIES } from "../constants/constants";


function ItbCarousel({ autoplay = false, children }) {
    return (
        <CarouselWrapper>
            <Carousel
                showThumbs={false}
                showStatus={false}
                autoPlay={autoplay}
                useKeyboardArrows={true}
                infiniteLoop={true}
                interval={3000}
            >
                {children}
            </Carousel>
        </CarouselWrapper>
    )
}

const CarouselWrapper = styled.div`
    .dot {
        background-color: ${COLORS.primary} !important;
    }

    .control-arrow {
        font-size: 26px;
        top: 50% !important;
        padding: 5px;
        margin-right: 50px;
        margin-left: 50px;
        border-radius: 50%;
        height: 40px;
        width: 40px;
        background-color: black !important;
        opacity: 0.8 !important;
        &:hover {
            opacity: 1 !important;
        }
        @media ${MEDIA_QUERIES.tabletAndSmaller} {
            display: none;
        }
    }

    .control-arrow::before {
        border-style: solid !important;
        border-color: white !important;
        border-width: 4px 4px 0 0 !important;
        content: '';
        display: inline-block;
        height: 12px;
        position: relative;
        top: 9px !important;
        transform: rotate(-45deg);
        vertical-align: top;
        width: 0.45em;
    }
    .control-next:before {
        right: 2px;
        transform: rotate(45deg);
    }
    .control-prev:before {
        left: 1px !important;
        transform: rotate(-135deg);
    }
`;

export default ItbCarousel;
