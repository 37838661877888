import React from "react";
import styled from "styled-components/macro";
import Year from '../campaign/year';
import Month from '../campaign/month';
import CompleteDate from './completeDate';
import DateRange from './dateRange';

function CampaignHeader({ event }) {
    if (event.dateType === "year") {
        return (
            <CampaignHeaderWrapper>
                <Year year={event.year} />
                <Divider />
                <H3> {event.place} </H3>
            </CampaignHeaderWrapper>);
    } else if (event.dateType === "dateRange") {
        return (
            <CampaignHeaderWrapper>
                <DateRange startDate={event.startDate} endDate={event.endDate} />
                <Divider />
                <H3Range> {event.place} </H3Range>
            </CampaignHeaderWrapper>);
    } else if (event.dateType === "date") {
        return (
            <CampaignHeaderWrapper>
                <CompleteDate date={event.date} month={event.month} year={event.year} />
                <Divider />
                <H3> {event.place} </H3>
            </CampaignHeaderWrapper>);
    } else {
        return (
            <CampaignHeaderWrapper>
                <Month month={event.month} year={event.year} />
                <Divider />
                <H3> {event.place} </H3>
            </CampaignHeaderWrapper>);
    }
};

const CampaignHeaderWrapper = styled.div`
    display: flex;
    align-items: stretch;
    margin-top: 2vw;
    margin-bottom: 2vw;
`;

const Divider = styled.div`
    width: 2px;
    margin-left: 10px;
    margin-right: 10px;
    background: #C4C4C4;
`;

const H3 = styled.h3`
    font-size: 1.5rem;
    display: flex;
    align-items: center;
`;

const H3Range = styled.h3`
    font-size: 1.5rem;
    display: flex;
    align-items: center;
`;

export default CampaignHeader;
