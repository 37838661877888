import { React } from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

import { ContainerRow, H5 } from '../components/elements';
import { COLORS, FONT_SIZES, MEDIA_QUERIES } from '../constants/constants';
import Icon from '../styles/Icon';
import Button from '../styles/lib';
import Brand from './brand';
import SocialIcons from '../components/social-icons';

function Footer() {
    return (
        <>
            <FooterWrapper>
                <ContainerRowWrapper>
                    <FooterSection>
                        <FooterSectionContent>
                            <div style={{marginTop: '-10px', fontWeight: 600}}>
                                <Brand></Brand>
                            </div>
                            <div  style={{marginTop: '10px'}} className="small-text">
                                At Inside The Box Trust, we believe in joining hands with multiple stakeholders to bring positive changes in the education system.
                            </div>
                            <Link to="/donate">
                                <Button styles={{width: '5rem', fontSize: '0.875rem', margin: '10px 0'}} size="small">Donate</Button>
                            </Link>
                            <SocialIcons />
                        </FooterSectionContent>
                    </FooterSection>

                    <DesktopFooterSection>
                        <FooterHeading>Quick Links</FooterHeading>
                        <FooterSectionContent>
                            <Link to="/faq">FAQ</Link>
                            <Link to="/team">Our Team members</Link>
                            <Link to="/ngo-model">Our NGO Model</Link>
                            <Link to="/who-we-are">About us</Link>
                        </FooterSectionContent>
                    </DesktopFooterSection>
                    <FooterSection>
                        <FooterHeading>Legal</FooterHeading>
                        <FooterSectionContent>
                            <Link to="/privacy">Privacy Policy</Link>
                            <Link to="/tnc">Terms and Conditions</Link>
                        </FooterSectionContent>
                    </FooterSection>
                    <FooterSection>
                        <FooterHeading>Contact Us</FooterHeading>
                        <FooterSectionContent>
                            <ContactUsItem>
                                <Icon id="phone"/>
                                <p>96869 44344</p>
                            </ContactUsItem>
                            <ContactUsItem>
                                <Icon id="mail"/>
                                <p>insidetheboxtrust.contact@gmail.com</p>
                            </ContactUsItem>
                            <ContactUsItem>
                                <Icon id="pin"/>
                                <p>INSIDE THE BOX TRUST<br />
                                151, 2nd Cross, 3rd Main<br />
                                AMCO Layout<br />
                                Sahakaranagar Post<br />
                                Bangalore - 560092</p>
                            </ContactUsItem>
                        </FooterSectionContent>
                    </FooterSection>
                </ContainerRowWrapper>
            </FooterWrapper>
            <Copyright>
                Copyright © 2021 INSIDE THE BOX TRUST. All Rights Reserved.
            </Copyright>
        </>
      )
}

const FooterHeading = styled(H5)`
    line-height: 2.5rem;
`;

const FooterWrapper = styled.footer`
    display: flex;
    padding: 10px;
    margin-top: 40px;
    font-size: ${FONT_SIZES.small}rem;
    background-color: ${COLORS.dark};
    justify-content: space-between;
    color: ${COLORS.white};
`;

const ContainerRowWrapper = styled(ContainerRow)`
    @media ${MEDIA_QUERIES.tabletAndSmaller} {
        max-width: 100%;
        flex-direction: column;
        padding: 5px;
        gap: 20px;
    }
`;

const FooterSection = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 25%;
    padding: 30px;
    gap: 10px;
    @media ${MEDIA_QUERIES.tabletAndSmaller} {
        max-width: 100%;
        padding: 10px;
    }
    h5 {
        font-weight: 600 !important;
    }
`;

const DesktopFooterSection = styled(FooterSection)`
    @media ${MEDIA_QUERIES.tabletAndSmaller} {
        // display: none;
    }
`;

const Copyright = styled.div`
    display: flex;
    justify-content: center;
    font-size: ${FONT_SIZES.small}rem;
    background-color: ${COLORS.dark};
    padding: 10px;
    color: ${COLORS.white};

    @media ${MEDIA_QUERIES.tabletAndSmaller} {
        font-size: ${FONT_SIZES.xs}rem;
    }
`

const FooterSectionContent = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    gap: 10px;
    font-size: ${FONT_SIZES.small}rem;
    a {
        color: ${COLORS.white};
        &:hover {
            text-decoration: none;
            color: ${COLORS.gray[300]};
        }
    }
`;

const ContactUsItem = styled.div`
    display: flex;
    gap: 10px;
    word-break: break-all;
`;

export default Footer;
