import styled from 'styled-components/macro'
import { React, useState } from 'react';
import { Link } from 'react-router-dom';

import { COLORS, MEDIA_QUERIES } from '../constants/constants';
import DropdownMenu from '../js/dropdown-menu/dropdown-menu';
import UnstyledButton from '../styles/UnstyledButton.js';
import Icon from '../styles/Icon.js';
import MobileMenu from './mobile-menu.js';
import Button from '../styles/lib';
import Brand from './brand';
import { ContainerRow, NavLink } from '../components/elements';
import ImageSrcConstants from '../constants/ImageSrcConstants';

function Menu({ onDismiss }) {
  return (
    <>
      <DropdownMenu title="About&nbsp;Us">
        <NavLink as={Link} onClick={onDismiss} to="/who-we-are">Who Are We</NavLink>
        <NavLink as={Link} onClick={onDismiss} to="/ngo-model">NGO model</NavLink>
        <NavLink as={Link} onClick={onDismiss} to="/team">Team</NavLink>
      </DropdownMenu>
      <DropdownMenu title="Campaigns">
        <NavLink as={Link} onClick={onDismiss} to="/iplant">IPlant</NavLink>
        <NavLink as={Link} onClick={onDismiss} to="/green-initiative">Green Initiative</NavLink>
        <NavLink as={Link} onClick={onDismiss} to="/donation-drive">Donation drive</NavLink>
        <NavLink as={Link} onClick={onDismiss} to="/shiksha">Shiksha</NavLink>
        <NavLink as={Link} onClick={onDismiss} to="/arts-and-creativity">Arts and Creativity</NavLink>
      </DropdownMenu>
      <NavLink as={'a'} onClick={onDismiss} href={ImageSrcConstants.VOLUNTEER_REG_FORM} rel="noopener noreferrer" target="_blank">Volunteer&nbsp;With&nbsp;Us</NavLink>
      <a href='/donate'><Button size="small">Donate</Button></a>
      <PhoneWrapper><Icon id="phone" size="16" />9686944344</PhoneWrapper>
    </>
  )
}

function Header() {

  const [showMobileMenu, setShowMobileMenu] = useState(false);
  return (
    <HeaderWrapper>
      <MainHeader>
        <HeaderContainer>
          <BrandWrapper>
            <NavLink as={Link} to="/home"><Brand /></NavLink>
          </BrandWrapper>
          <DesktopNav>
            <Menu />
          </DesktopNav>
          <Filler></Filler>
          <MobileToggler>
            <UnstyledButton onClick={() => setShowMobileMenu(true)}><Icon id="menu"></Icon></UnstyledButton>
          </MobileToggler>
        </HeaderContainer>
      </MainHeader>
      <MobileMenu
        isOpen={showMobileMenu}
        onDismiss={() => setShowMobileMenu(false)}
      >
        <Menu onDismiss={() => setShowMobileMenu(false)} />
      </MobileMenu>

    </HeaderWrapper>
  )
}

const HeaderWrapper = styled.header`
    position: sticky;
    top: 0;
    z-index: 1000;
    background: ${COLORS.white};
`;

const HeaderContainer = styled(ContainerRow)`
    gap: 100px;
    @media ${MEDIA_QUERIES.laptopAndSmaller} {
        gap: 0;
    }
`;

const MainHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 18px 32px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);

  @media ${MEDIA_QUERIES.tabletAndSmaller} {
    justify-content: space-between;
  }

  @media ${MEDIA_QUERIES.phone} {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const DesktopNav = styled.nav`
  display: flex;
  gap: 48px;
  margin: 0px 48px;
  align-items: center;

  @media ${MEDIA_QUERIES.laptopAndSmaller} {
    gap: 24px;
  }

  @media ${MEDIA_QUERIES.bigTabletAndSmaller} {
    gap: 16px;
  }

  @media ${MEDIA_QUERIES.tabletAndSmaller} {
    display: none;
  }
`;

const MobileToggler = styled.div`
  display: none;
  @media ${MEDIA_QUERIES.tabletAndSmaller} {
    display: flex;
    align-items: center;
  }
`;

const BrandWrapper = styled.div`
  @media ${MEDIA_QUERIES.tabletAndSmaller} {
      flex: revert;
  }
`;
const PhoneWrapper = styled.div`
  display: flex;
  gap: 4px;
  font-weight: 600;
`;
const Filler = styled.div`
  flex: 1;
  @media ${MEDIA_QUERIES.tabletAndSmaller} {
      display: none;
  }
`;

export default Header;
