import styled from 'styled-components/macro'
import React, { Component } from 'react';
import { H3 } from '../../components/elements';

class mission extends Component {
    render() {
        return (
            <Mission>
                <H3>OUR MISSION</H3>
                <P>Provide quality life education to the underpriviledged children by creating a hands on learning environment thereby equipping them to a build a better and sustainable future.</P>
            </Mission>
        )
    }
}

const Mission = styled.div`
    background-color: #EFEFEF;
    padding-top: 2%;
    padding-bottom: 2%;
    padding-left: 20%;
    padding-right: 20%;
`;

const P = styled.div`
    text-align:justify;
`;

export default mission;