import styled from "styled-components/macro";

import { COLORS, MEDIA_QUERIES, WEIGHTS } from "../constants/constants"

export const H1 = styled.h1`
    font-size: 2rem;
    font-weight: 500;
    margin: 40px 0;
`;

export const PageHeading = styled(H1)`
    display: flex;
    justify-content: center;
`;

export const PageHeadingWithCaption = styled.h1`
    font-size: 2rem;
    font-weight: 500;
    margin-top: 40px;
    display: flex;
    justify-content: center;
`;

export const Caption = styled.p`
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
`;

export const H2 = styled.h2`
    font-size: 1.75rem;
    font-weight: 500;
    margin: 20px 0;
`;

export const H2NoMargin = styled.h2`
    font-size: 1.75rem;
    font-weight: 500;
`;

export const H3 = styled.h3`
    font-size: 1.5rem;
    font-weight: 500;
    margin: 15px 0;
`;

export const H3MinMargin = styled.h3`
    font-size: 1.5rem;
    font-weight: 500;
    margin: 5px 0;
`;

export const H4 = styled.h4`
    font-size: 1.2rem;
    margin: 10px 0;
`;

export const H4NoMargin = styled.h4`
    font-size: 1.2rem;
`;

export const H5 = styled.h5`
    font-size: 1rem;
    font-weight: 500;
    margin-botton: 1rem;
`;

export const Section = ({ bgColor = COLORS.white, styles = {}, children }) => {
    return (
        <SectionWrapper bgColor={bgColor} style={styles}>
            <Container>
                { children }
            </Container>
        </SectionWrapper>
    )
}

export const SectionWrapper = styled.section`
    padding-top: 32px;
    padding-bottom: 32px;
    background-color: ${props => props.bgColor};
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 80vw;
    max-width: 80vw;
    margin: 0 auto;
    @media ${MEDIA_QUERIES.bigTabletAndSmaller} {
        max-width: 95vw;
        width: 100vw;
        padding: 0 16px;
    }
`;

export const ContainerRow = styled(Container)`
    flex-direction: row;
    justify-content: space-between;
`;

export const SectionContents = styled.div`
    display: flex;
    gap: 20px;
    justify-content: space-between;
`;

export const NavLink = styled.div`
  font-size: 0.875rem;
  text-decoration: none;
  font-weight: ${WEIGHTS.medium};
  color: ${COLORS.black};
  &:hover {
    text-decoration: none;
    color: ${COLORS.primary};
  }
  @media ${MEDIA_QUERIES.tabletAndSmaller} {
    font-size: 1rem;
  }
`;
