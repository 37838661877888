import React, { Component } from 'react';
import styled from 'styled-components/macro';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import ImageSrcConstants from '../../constants/ImageSrcConstants';
import ItbCarousel from '../itb-carousel';

class HeroImages extends Component {
  render() {
    return (
      <ItbCarousel autoplay={true}>
        {ImageSrcConstants.HOME_PAGE.map((hero, i) => (
           <div
            key={hero.src}
           >
            <Img
              src={hero.src}
              alt={hero.altText}
            />
          </div>
        ))}
      </ItbCarousel>
    )
  }
}

const Img = styled.img`
    max-height: 85vh;
    min-height: 40vh;
    width: 100%;
    object-fit: cover;
`;

export default HeroImages;
