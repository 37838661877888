const projectLeadsInfo = {
    DATA: [
      {
        name: "SHRADDHA K",
        designation: "TEACHING",
        img: "https://drive.google.com/thumbnail?id=1dT9rRvMR7z6z4Shj7VnqwPZZENEm3ecj"
      },
      {
        name: "SOUMYA",
        designation: "ONLINE ACTIVITIES",
        img: "https://drive.google.com/thumbnail?id=1CpYouIdAG-WCiXjOXliJTeamXXx7LWgX"
      },
      {
        name: "NAVNEET",
        designation: "TEACHING",
        img: "https://drive.google.com/thumbnail?id=1BNw7l78lotoymZII2jEfiECUL0V1oeFA"
      },
      {
        name: "ANIL",
        designation: "CLEANUP DRIVES",
        img: "https://drive.google.com/thumbnail?id=1c4H9FSpdwQIUtwOE2DnkJM74X5cgpvak"
      },
      {
        name: "DEEPIKA",
        designation: "CLEANUP DRIVES",
        img: "https://drive.google.com/thumbnail?id=1E86NQi2eAHO69H2NXam7WJuGQyh9Jm1e"
      },
      {
        name: "SHUBADA",
        designation: "GREEN INITIATIVES",
        img: "https://drive.google.com/thumbnail?id=1aD5ThNCVuIcn8H_xoIETSsM6sGDl7MD_"
      },
      {
        name: "SHIVRAJ",
        designation: "DIGITAL MARKETING",
        img: "https://drive.google.com/thumbnail?id=1-7WmzkQyo_Y68biJmfAJ58RgWGgMKj_k"
      },
      {
        name: "SATHEESH",
        designation: "HUMAN RESOURCES",
        img: "https://drive.google.com/thumbnail?id=1r1jGLDHbRpw8-AykieHGxsh5Etl7wGlC"
      },
      {
        name: "VARTIKA AGRAWAL",
        designation: "TECH LEAD",
        img: "https://drive.google.com/thumbnail?id=1ssBPoAV8xx2BttA5Euw2GEBTWKG0_HEK"
      }
    ]
}

export default projectLeadsInfo;